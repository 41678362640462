import { UseQueryOptions } from "@tanstack/react-query";

import { useCustomQuery } from "@/hooks/api";
import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

interface Permission {
  permission_id: number;
  name_short: string;
  name_long: string;
}

interface UserWithPermissions {
  user_id: number;
  name_long: string;
  permission_ids: number[];
  permission_name_shorts: string[];
}

export interface UserType {
  user_type_id: number;
  name_short: "admin" | "superadmin" | "user";
}

export const useGetUserType = ({
  queryOptions = {},
}: {
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: "/v1/admin/user-type",
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<UserType>({
    axiosConfig,
    queryName: "getUserType",
    pathParams: {},
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetCompanyPermissions = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/admin/permissions/projects/${pathParams.projectId}/company`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<Permission[]>({
    axiosConfig,
    queryName: "getCompanyPermissions",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetCompanyUsersPermissions = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/admin/permissions/projects/${pathParams.projectId}/company-users`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<UserWithPermissions[]>({
    axiosConfig,
    queryName: "getCompanyUsersPermissions",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetUserPermissions = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/admin/permissions/projects/${pathParams.projectId}/user`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<Permission[]>({
    axiosConfig,
    queryName: "getUserPermissions",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useUpdateUserPermissionMutation = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const baseURL = import.meta.env.PROD
    ? "https://api.proximal.energy"
    : "http://127.0.0.1:8000";

  return useMutation({
    mutationFn: async ({
      userId,
      projectId,
      permissionId,
      grant,
    }: {
      userId: string;
      projectId: string;
      permissionId: number;
      grant: boolean;
    }) => {
      const token = await getToken({ template: "default" });
      return axios({
        method: grant ? "post" : "delete",
        url: `${baseURL}/v1/admin/permissions/projects/${projectId}/users/${userId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          permission_id: permissionId,
        },
      });
    },
    onMutate: async ({ userId, projectId, permissionId, grant }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ["getCompanyUsersPermissions", { projectId }],
      });

      // Snapshot the previous value
      const previousUserPermissions = queryClient.getQueryData<
        UserWithPermissions[]
      >(["getCompanyUsersPermissions", { projectId }]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["getCompanyUsersPermissions", { projectId }],
        (old?: UserWithPermissions[]) => {
          if (!old) return old;
          return old.map((user) => {
            if (user.user_id.toString() === userId) {
              const newPermissionIds = grant
                ? [...user.permission_ids, permissionId]
                : user.permission_ids.filter((id) => id !== permissionId);
              return {
                ...user,
                permission_ids: newPermissionIds,
              };
            }
            return user;
          });
        }
      );

      // Return a context object with the snapshotted value
      return { previousUserPermissions };
    },
    onError: (_err, variables, context) => {
      // If the mutation fails, roll back to the previous value
      if (context?.previousUserPermissions) {
        queryClient.setQueryData(
          ["getCompanyUsersPermissions", { projectId: variables.projectId }],
          context.previousUserPermissions
        );
      }
    },
    onSettled: (_data, _error, variables) => {
      // Always refetch after error or success to ensure we're in sync with server
      queryClient.invalidateQueries({
        queryKey: [
          "getCompanyUsersPermissions",
          { projectId: variables.projectId },
        ],
      });
    },
  });
};
