import CustomCard from "@/components/CustomCard";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { useGetEquipmentAnalysisBESSBlock, useGetHeatmap } from "@/hooks/api";
import { Stack, Title } from "@mantine/core";
import { useParams } from "react-router-dom";

const Page = () => {
  const { projectId } = useParams();

  const data = useGetEquipmentAnalysisBESSBlock({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: { enabled: !!projectId },
  });

  const heatmap = useGetHeatmap({
    pathParams: {
      projectId: projectId || "-1",
      sensorTypeName: "bess_block_soc_percent",
    },
    queryParams: {
      fillna_zero: false,
    },
  });

  return (
    <Stack p="md" h="100%">
      <Title order={1}>BESS Block Equipment Analysis</Title>
      <CustomCard title="Block SOC Distribution" style={{ flex: 1 }}>
        <PlotlyPlot
          data={
            data.data && [
              {
                x: data.data.x,
                y: data.data.y,
                type: "bar",
              },
            ]
          }
          layout={{
            yaxis: {
              tickformat: ".0%",
            },
            xaxis: { title: "Block" },
          }}
          isLoading={data.isLoading}
          error={data.error}
        />
      </CustomCard>
      <CustomCard title="Block SOC Heatmap" style={{ flex: 1 }}>
        <PlotlyPlot
          data={[
            {
              z: heatmap.data?.z,
              x: heatmap.data?.x,
              y: heatmap.data?.y,
              type: "heatmap",
            },
          ]}
          colorscale={"primary"}
          isLoading={heatmap.isLoading}
          error={heatmap.error}
          layout={{
            yaxis: { title: "Block" },
          }}
        />
      </CustomCard>
    </Stack>
  );
};

export default Page;
