import { UserProfile } from "@clerk/clerk-react";
import { Center } from "@mantine/core";

export const AccountSettings = () => {
  return (
    <Center p="md">
      <UserProfile path="/account-settings" routing="path" />
    </Center>
  );
};

export default AccountSettings;
