import { useGetUserType } from "@/api/admin";
import { Stack, Text, Title } from "@mantine/core";
import { PageError } from "../Error";
import { PageLoader } from "../Loading";

type RequiresUserTypeProps = {
  requiredUserType: "admin" | "superadmin";
  children: React.ReactNode;
};

const RequiresUserType = ({
  requiredUserType,
  children,
}: RequiresUserTypeProps) => {
  const userType = useGetUserType({});

  if (userType.isLoading) {
    return <PageLoader />;
  }

  if (userType.error) {
    return <PageError error={userType.error} />;
  }

  // Admin
  // Requires the user to be an admin or superadmin
  if (requiredUserType === "admin") {
    if (
      userType.data?.name_short !== "admin" &&
      userType.data?.name_short !== "superadmin"
    ) {
      return <PermissionDenied />;
    }
  }

  // Superadmin
  // Requires the user to be a superadmin
  if (requiredUserType === "superadmin") {
    if (userType.data?.name_short !== "superadmin") {
      return <PermissionDenied />;
    }
  }

  return <>{children}</>;
};

const PermissionDenied = () => {
  return (
    <Stack p="md">
      <Title order={3}>Permission Denied</Title>
      <Text>
        You do not have permission to access this page. Please reach out to your
        company admin to request access.
      </Text>
    </Stack>
  );
};

export default RequiresUserType;
