import { useGetUserType } from "@/api/admin";
import { useClerk, useUser } from "@clerk/clerk-react";
import { Avatar, Menu, rem, UnstyledButton } from "@mantine/core";
import {
  IconCode,
  IconDeviceDesktopCog,
  IconLogout2,
  IconUserCog,
  IconUsers,
} from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";

const UserDropdown = () => {
  const { signOut } = useClerk();
  const userType = useGetUserType({});
  const { projectId } = useParams();
  const { user } = useUser();

  const initials =
    user?.firstName && user?.lastName
      ? `${user.firstName[0]}${user.lastName[0]}`
      : user?.firstName?.[0] || "";

  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <UnstyledButton>
          <Avatar
            src={user?.hasImage ? user?.imageUrl : undefined}
            alt={user?.fullName || "User avatar"}
            radius="xl"
          >
            {initials}
          </Avatar>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown style={{ zIndex: 800 }}>
        <Menu.Item
          component={Link}
          to="/account-settings"
          leftSection={
            <IconUserCog style={{ width: rem(14), height: rem(14) }} />
          }
        >
          Account Settings
        </Menu.Item>
        <Menu.Item
          component={Link}
          to="/application-settings"
          leftSection={
            <IconDeviceDesktopCog style={{ width: rem(14), height: rem(14) }} />
          }
        >
          Application Settings
        </Menu.Item>
        {userType.data?.name_short === "superadmin" && (
          <Menu.Item
            component={Link}
            to="/api"
            leftSection={
              <IconCode style={{ width: rem(14), height: rem(14) }} />
            }
          >
            API
          </Menu.Item>
        )}
        {userType.data?.name_short !== "user" && projectId && (
          <>
            <Menu.Divider />
            <Menu.Item
              component={Link}
              to={`/projects/${projectId}/admin`}
              leftSection={
                <IconUsers style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Admin
            </Menu.Item>
          </>
        )}
        <Menu.Divider />
        <Menu.Item
          onClick={() => signOut()}
          leftSection={
            <IconLogout2 style={{ width: rem(14), height: rem(14) }} />
          }
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserDropdown;
