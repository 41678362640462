import { useGetProject } from "@/hooks/api";
import {
  BackgroundImage,
  Overlay,
  Stack,
  Text,
  useComputedColorScheme,
} from "@mantine/core";

const ProjectPicture = ({
  project,
  showText,
}: {
  project: ReturnType<typeof useGetProject>["data"];
  showText: boolean;
}) => {
  const computedColorScheme = useComputedColorScheme();

  return (
    <BackgroundImage
      src={
        project?.image_url ??
        (project?.project_type_id === 2
          ? "https://iea.imgix.net/d771a759-2355-4f8e-89f4-d53e762d1047/shutterstock_1514163416.jpg?auto=compress%2Cformat&fit=min&h=630&q=80&rect=987%2C0%2C7013%2C3943&w=1200"
          : "https://www.renewableenergyworld.com/wp-content/uploads/2021/09/muscle-shoals-1200x675.png")
      }
      radius={0}
      h={150}
      pos="relative"
    >
      <Overlay
        color={computedColorScheme === "dark" ? "#242424" : "#fff"}
        backgroundOpacity={computedColorScheme === "dark" ? 0.5 : 0.7}
        zIndex={1}
      />
      <Stack p="md" gap={0} h="100%" justify="end">
        {showText && (
          <>
            <Text size="xl" fw={800} lh={1} style={{ zIndex: 2 }}>
              {project?.name_long}
            </Text>
            <Text size="xl" fw={600} fs="italic" lh={1} style={{ zIndex: 2 }}>
              {project?.poi} MW {project?.project_type?.name_long}
            </Text>
          </>
        )}
      </Stack>
    </BackgroundImage>
  );
};

export default ProjectPicture;
