import CustomCard from "@/components/CustomCard";
import { PageError } from "@/components/Error";
import KPICard, { EmptyKPICard } from "@/components/KPICard";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import WeatherCard from "@/components/WeatherCard";
import {
  useGetKPISummary,
  useGetMeterPowerAndExpectedPower,
  useGetPaginatedEvents,
  useGetProject,
  useGetTimeSeries,
} from "@/hooks/api";
import { Quality } from "@/hooks/types";
import BessEnclosureGIS from "@/pages/projects/gis/bess-enclosure-gis";
import { PCSGISMap } from "@/pages/projects/gis/pcs-gis";
import {
  ActionIcon,
  Card,
  Group,
  HoverCard,
  List,
  LoadingOverlay,
  Popover,
  rem,
  SegmentedControl,
  Skeleton,
  Space,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from "@mantine/core";
import {
  IconCheck,
  IconExclamationMark,
  IconLetterQ,
  IconSettings,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// interface jsonData {
//   project_quality: number | null;
//   project_mechanical_availability: number | null;
// }

// const PCSMechAvail = () => {
//   const { projectId } = useParams();

//   const { data, isLoading, error } = useGetProjectPCSMechanicalAvailabilityData(
//     {
//       pathParams: { projectId: projectId || "-1" },
//       queryOptions: {
//         refetchOnWindowFocus: false,
//         staleTime: 60 * 1000,
//       },
//     }
//   );

//   if (isLoading) return;
//   if (!data) return;
//   if (error) return;

//   const latestData: jsonData = (
//     data.json[data.json.length - 1] !== null
//       ? data.json[data.json.length - 1]
//       : { project_quality: 0, project_mechanical_availability: 0 }
//   ) as jsonData;

//   const getQualityLevel = (qualityValue: number): QualityLevel => {
//     if (qualityValue >= 0.9) return "good";
//     if (qualityValue >= 0.8) return "warning";
//     return "bad";
//   };
//   const qualityData =
//     latestData.project_quality !== null ? latestData.project_quality : 0;
//   const availabilityData =
//     latestData.project_mechanical_availability !== null
//       ? latestData.project_mechanical_availability
//       : 0;

//   const createQuality = (qualityValue: number): Quality => {
//     const level = getQualityLevel(qualityValue);
//     const message = `Quality is ${(qualityValue * 100).toFixed(1)} %`;

//     return {
//       level,
//       message,
//       details: [
//         {
//           level,
//           message,
//         },
//       ],
//     };
//   };

//   const quality = createQuality(qualityData);
//   return (
//     <StatCard
//       title="Yesterday's PCS Mechanical Availability"
//       value={availabilityData * 100}
//       suffix="%"
//       info="Calculated as the ratio of the total number of hours PCSs were available to the total number of hours in the period."
//       quality={quality} // Pass the quality data
//     ></StatCard>
//   );
// };

function QualityCard({ quality }: { quality: Quality }) {
  const colorMap = {
    good: "green",
    warning: "yellow",
    bad: "red",
  };

  const iconMap = {
    good: <IconCheck style={{ width: rem(16), height: rem(16) }} />,
    warning: (
      <IconExclamationMark style={{ width: rem(16), height: rem(16) }} />
    ),
    bad: <IconExclamationMark style={{ width: rem(16), height: rem(16) }} />,
  };

  return (
    <HoverCard shadow="md">
      <HoverCard.Target>
        <ThemeIcon color={colorMap[quality.level]} size={20} radius="xl">
          <IconLetterQ style={{ width: rem(16), height: rem(16) }} />
        </ThemeIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text>{quality.message}</Text>
        <Space h="xs" />
        <List spacing="xs" size="sm" center>
          {quality.details.map((detail, i) => (
            <List.Item
              key={i}
              icon={
                <ThemeIcon color={colorMap[detail.level]} size={20} radius="xl">
                  {iconMap[detail.level]}
                </ThemeIcon>
              }
            >
              {detail.message}
            </List.Item>
          ))}
        </List>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const PowerPlotPV = () => {
  const { projectId } = useParams();
  const theme = useMantineTheme();

  const colorMap = {
    "Meter Active Power": theme.colors.green[7],
    "Expected Power": theme.colors.orange[7],
    "PV Circuit Active Power": theme.colors.blue[7],
    "Bess Circuit Active Power": theme.colors.yellow[7],
  };

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const data = useGetMeterPowerAndExpectedPower({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { include_storage: project.data?.project_type_id === 3 },
    queryOptions: { enabled: !!project.data },
  });

  return (
    <CustomCard
      title="Meter Power"
      quality={data.data && <QualityCard quality={data.data.quality} />}
      style={{ flex: 2 }}
    >
      <PlotlyPlot
        data={data.data?.data.map((d) => ({
          x: d.x,
          y: d.y,
          name: d.name,
          hoverlabel: {
            namelength: -1,
          },
          fill: d.name === "Meter Active Power" ? "tozeroy" : "none",
          line: {
            color:
              colorMap[d.name as keyof typeof colorMap] ||
              theme.colors.orange[7],
          },
        }))}
        layout={
          project.data && {
            yaxis: {
              title: "Power (MW)",
              range: [
                project.data.project_type_id !== 1 ? undefined : 0,
                project.data.poi * 1.05,
              ],
            },
          }
        }
        isLoading={data.isLoading || project.isLoading}
        error={data.error}
      />
    </CustomCard>
  );
};

const PowerPlotBESS = () => {
  const { projectId } = useParams();
  const theme = useMantineTheme();

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const data = useGetTimeSeries({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      sensor_type_name_shorts: ["meter_active_power", "project_soc_percent"],
      start: dayjs()
        .subtract(24, "hours")
        .startOf("minute")
        .subtract(dayjs().minute() % 5, "minutes")
        .toISOString(),
      end: dayjs()
        .startOf("minute")
        .subtract(dayjs().minute() % 5, "minutes")
        .toISOString(),
    },
  });

  return (
    <CustomCard title="Meter Power" style={{ flex: 2 }}>
      <PlotlyPlot
        data={data.data?.map((d) => ({
          x: d.x,
          y: d.y,
          name: d.name,
          fill: d.sensor_type_name === "project_soc_percent" ? null : "tozeroy",
          line: {
            color:
              d.sensor_type_name === "project_soc_percent"
                ? theme.colors.blue[7]
                : theme.colors.green[7],
          },
          yaxis: d.sensor_type_name === "project_soc_percent" ? "y2" : "y",
        }))}
        layout={
          project.data && {
            yaxis: {
              title: "Power (MW)",
              range: [project.data.poi * 1.05 * -1, project.data.poi * 1.05],
            },
            yaxis2: {
              title: "SOC",
              range: [0, 1.05],
              overlaying: "y",
              side: "right",
              tickformat: ".0%",
            },
          }
        }
        isLoading={data.isLoading}
        error={data.error}
      />
    </CustomCard>
  );
};

const PowerPlot = ({ projectType }: { projectType: number }) => {
  if (projectType === 2) {
    return <PowerPlotBESS />;
  }
  return <PowerPlotPV />;
};

const CurrentTime = ({ timezone }: { timezone: string }) => {
  const formattedTime = () => {
    return dayjs().tz(timezone).format("MMM D, YYYY HH:mm:ss z");
  };

  const [currentTime, setCurrentTime] = useState(formattedTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(formattedTime());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  });

  return (
    <Text size="sm" style={{ fontFamily: "monospace" }}>
      {currentTime}
    </Text>
  );
};

const KPICards = () => {
  const { projectId } = useParams();

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const kpiTypeIdsMap = {
    1: [1, 8, 13],
    2: [9, 16],
    3: [1, 9, 16],
  };

  const data = useGetKPISummary({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      kpi_type_ids: project.data
        ? kpiTypeIdsMap[
            project.data.project_type_id as keyof typeof kpiTypeIdsMap
          ]
        : [],
    },
    queryOptions: {
      enabled: !!project.data,
    },
  });

  if (project.isLoading || data.isLoading) {
    return (
      <Skeleton radius="md">
        <EmptyKPICard />
      </Skeleton>
    );
  }

  return (
    <Group>
      {data.data?.map((kpi, i) => (
        <KPICard key={i} {...kpi} link={`kpis/${kpi.link}`} />
      ))}
    </Group>
  );
};

import { ScrollArea, Table } from "@mantine/core";

const EventTable = () => {
  const { projectId } = useParams();
  const [sortBy, setSortBy] = useState("loss_total_financial");

  const { data: topEvents, isLoading: isLoadingTopEvents } =
    useGetPaginatedEvents({
      pathParams: { projectId: projectId || "-1" },
      queryParams: {
        page: 1,
        page_size: 5,
        open: true,
        sort_column: sortBy,
        sort_direction: "desc",
        device_type_ids: [],
      },
      queryOptions: {
        staleTime: 60 * 1000,
      },
    });

  const formatLoss = (value: number | null) => {
    if (value === 0 || value === null) {
      return "-";
    }
    return `$${value.toFixed(2)}`;
  };

  return (
    <CustomCard
      title="Top Events"
      fill
      headerChildren={
        <Popover>
          <Popover.Target>
            <ActionIcon variant="default">
              <IconSettings />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown>
            <Text>Sort By</Text>
            <SegmentedControl
              data={[
                { label: "Total Loss", value: "loss_total_financial" },
                { label: "Daily Loss", value: "loss_daily" },
              ]}
              value={sortBy}
              onChange={(value) => setSortBy(value ?? "loss_total_financial")}
            />
          </Popover.Dropdown>
        </Popover>
      }
      style={{ flex: 1 }}
    >
      <LoadingOverlay visible={isLoadingTopEvents} />
      <ScrollArea h="100%">
        <Table striped highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Device</Table.Th>
              <Table.Th style={{ textAlign: "center" }}>Loss - Daily</Table.Th>
              <Table.Th style={{ textAlign: "center" }}>Loss - Total</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {topEvents?.map((event) => (
              <Table.Tr key={event.event_id}>
                <Table.Td>
                  <Link
                    to={`/projects/${projectId}/events/event/?eventId=${event.event_id}`}
                    style={{ color: "inherit" }}
                  >
                    {event.device_name_full}
                  </Link>
                </Table.Td>
                <Table.Td style={{ textAlign: "center" }}>
                  {formatLoss(event.loss_daily_financial)}
                </Table.Td>
                <Table.Td style={{ textAlign: "center" }}>
                  {formatLoss(event.loss_total_financial)}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </CustomCard>
  );
};

const ProjectHome = () => {
  const { projectId } = useParams();
  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      deep: true,
    },
  });

  if (project.isLoading) return <PageLoader />;
  if (project.isError) return <PageError error={project.error} />;
  if (project.data === undefined) return <PageError error={undefined} />;

  let mapComponent;
  if (project.data.project_type_id === 2) {
    mapComponent = <BessEnclosureGIS showTitleCard={false} />;
  } else {
    mapComponent = <PCSGISMap showTitleCard={false} />;
  }

  return (
    <Stack p="md" h="100%">
      <Group align="start">
        <Group gap="xs" flex={1}>
          <Title order={1} lh={1}>
            {project.data?.name_long}
          </Title>
          <Title order={1} fs="italic" fw="normal" lh={1}>
            {project.data?.poi} MW {project.data?.project_type?.name_long}
          </Title>
        </Group>
        <Group gap="xs">
          <WeatherCard />
          <Card p={5} withBorder>
            <CurrentTime timezone={project.data?.time_zone} />
          </Card>
        </Group>
      </Group>
      <KPICards />
      <Group flex={1}>
        <Stack h="100%" flex={1}>
          <CustomCard title="Block Performance" fill style={{ flex: 1 }}>
            {mapComponent}
          </CustomCard>
        </Stack>
        <Stack h="100%" flex={1}>
          {project.data.has_event_integration && <EventTable />}
          <PowerPlot projectType={project.data.project_type_id} />
        </Stack>
      </Group>
    </Stack>
  );
};

export default ProjectHome;
