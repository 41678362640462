import CustomCard from "@/components/CustomCard";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { useGetEquipmentAnalysisBESSPCS, useGetHeatmap } from "@/hooks/api";
import {
  Stack,
  Title,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useParams } from "react-router-dom";

const Page = () => {
  const { projectId } = useParams();
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme();

  const data = useGetEquipmentAnalysisBESSPCS({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: { enabled: !!projectId },
  });

  const heatmap = useGetHeatmap({
    pathParams: {
      projectId: projectId || "-1",
      sensorTypeName: "bess_pcs_ac_power",
    },
    queryParams: {
      fillna_zero: false,
    },
  });

  return (
    <Stack p="md" h="100%">
      <Title order={1}>BESS PCS Equipment Analysis</Title>
      <CustomCard title="PCS Output Distribution" style={{ flex: 1 }}>
        <PlotlyPlot
          data={
            data.data && [
              {
                x: data.data.x,
                y: data.data.y,
                type: "bar",
              },
            ]
          }
          isLoading={data.isLoading}
          error={data.error}
          layout={{
            xaxis: { title: "Block" },
            yaxis: { title: "kW" },
          }}
        />
      </CustomCard>
      <CustomCard
        title="PCS Power Heatmap"
        style={{ flex: 1 }}
        info="Green indicates discharging, while red indicates charging."
      >
        <PlotlyPlot
          data={[
            {
              z: heatmap.data?.z,
              x: heatmap.data?.x,
              y: heatmap.data?.y,
              type: "heatmap",
              colorscale: [
                [0, theme.colors.red[7]],
                [
                  0.5,
                  computedColorScheme === "dark"
                    ? theme.colors.dark[7]
                    : theme.colors.gray[0],
                ],
                [1, theme.colors.green[7]],
              ],
            },
          ]}
          isLoading={heatmap.isLoading}
          error={heatmap.error}
          layout={{
            yaxis: { title: "Block" },
          }}
        />
      </CustomCard>
    </Stack>
  );
};

export default Page;
